export const menuRoles = {
    itensMenu: {
        listaTelefonica: {
            roles: [
                'LISTA-TELEFONICA_EMISSORAS_DELETAR',
                'LISTA-TELEFONICA_EMISSORAS_VISUALIZAR',
                'LISTA-TELEFONICA_EMISSORAS_SALVAR',
                'LISTA-TELEFONICA_CONTATOS_DELETAR',
                'LISTA-TELEFONICA_CONTATOS_SALVAR',
                'LISTA-TELEFONICA_CONTATOS_VISUALIZAR'
            ],
            contato: [
                'LISTA-TELEFONICA_CONTATOS_DELETAR',
                'LISTA-TELEFONICA_CONTATOS_SALVAR',
                'LISTA-TELEFONICA_CONTATOS_VISUALIZAR'
            ],
            emissoras: [
                'LISTA-TELEFONICA_EMISSORAS_DELETAR',
                'LISTA-TELEFONICA_EMISSORAS_VISUALIZAR',
                'LISTA-TELEFONICA_EMISSORAS_SALVAR'
            ]
        },
        relatorios: {
            roles: [
                'RELATORIOS_CONTROLADORIA_DELETAR',
                'RELATORIOS_CONTROLADORIA_VISUALIZAR',
                'RELATORIOS_CONTROLADORIA_SALVAR'
            ],
            controladoria: [
                'RELATORIOS_CONTROLADORIA_DELETAR',
                'RELATORIOS_CONTROLADORIA_VISUALIZAR',
                'RELATORIOS_CONTROLADORIA_SALVAR'
            ]
        },
        parametros: {
            roles: [
                'PARAMETROS_TIPO-CONTATO_DELETAR',
                'PARAMETROS_TIPO-CONTATO_VISUALIZAR',
                'PARAMETROS_TIPO-CONTATO_SALVAR',
                'PARAMETROS_TIPO-VISUALIZACAO_SALVAR',
                'PARAMETROS_TIPO-VISUALIZACAO_DELETAR',
                'PARAMETROS_TIPO-VISUALIZACAO_VISUALIZAR',
                'PARAMETROS_SALA_SALVAR',
                'PARAMETROS_SALA_DELETAR',
                'PARAMETROS_SALA_VISUALIZAR'
            ],
            tipoContato: [
                'PARAMETROS_TIPO-CONTATO_DELETAR',
                'PARAMETROS_TIPO-CONTATO_VISUALIZAR',
                'PARAMETROS_TIPO-CONTATO_SALVAR'
            ],
            tipoVisualizacao: [
                'PARAMETROS_TIPO-VISUALIZACAO_SALVAR',
                'PARAMETROS_TIPO-VISUALIZACAO_DELETAR',
                'PARAMETROS_TIPO-VISUALIZACAO_VISUALIZAR'
            ],
            salas: [
                'PARAMETROS_SALAS_SALVAR',
                'PARAMETROS_SALAS_DELETAR',
                'PARAMETROS_SALAS_VISUALIZAR'
            ]
        },
        menu: {
            roles: ['TESTE_SALVAR', 'TESTE_DELETAR', 'TESTE_VISUALIZAR'],
            teste: {
                visualizar: ['TESTE_VISUALIZAR'],
                salvar: ['TESTE_SALVAR'],
                deletar: ['TESTE_DELETAR']
            }
        }
    }
};
