<nav-bar titulo="Lista Telefônica"
         [iconeNotificacoes]="false"
         [botaoMenu]="true"
         [botaoPainelAplicacoes]="true"
         [linkPainelAplicacoes]="URL_LOGIN"
         (logoutEvent)="logout($event)">
</nav-bar>
<menu-lateral [itensMenu]="menuItens">
    <copyright></copyright>
</menu-lateral>
