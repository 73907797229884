import { Component, Inject } from '@angular/core';
import {
    AlertMessageService,
    AplicacaoService,
    CookieService,
    IMenu,
    LocalStorageService,
    PERMISSOES_SERVICE_TOKEN,
    PermissoesService
} from '@sbt-suite/components';
import { environment } from 'src/environments/environment';
import { menuRoles } from './core/constants/roles';
import { ControladoriaService } from './core/services/relatorios/controladoria.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    MODE = environment.MODE;
    URL_LOGIN = environment.URL_LOGIN;
    URL_LOGOUT = environment.URL_LOGOUT;

    menuItens: IMenu[] = [
        {
            label: 'Lista Telefônica',
            roles: menuRoles.itensMenu.listaTelefonica.roles,
            itens: [
                {
                    label: 'Contatos',
                    icone: 'perm_contact_calendar',
                    link: '/lista-telefonica/contatos',
                    roles: Object.values(menuRoles.itensMenu.listaTelefonica.contato).flat()
                },
                {
                    label: 'Emissoras',
                    icone: 'badge',
                    link: '/lista-telefonica/emissoras',
                    roles: Object.values(menuRoles.itensMenu.listaTelefonica.emissoras).flat()
                }
            ]
        },
        {
            label: 'Relatórios',
            roles: menuRoles.itensMenu.relatorios.roles,
            itens: [
                {
                    label: 'Controladoria',
                    icone: 'list_alt',
                    action: () => this._getRelatorioControladoria(),
                    roles: Object.values(menuRoles.itensMenu.relatorios.controladoria).flat()
                }
            ]
        },
        {
            label: 'Parâmetros',
            roles: menuRoles.itensMenu.parametros.roles,
            itens: [
                {
                    label: 'Tipo Contato',
                    icone: 'contacts',
                    link: '/parametros/tipo-contato',
                    roles: Object.values(menuRoles.itensMenu.parametros.tipoContato).flat()
                },
                {
                    label: 'Tipo Visualização',
                    icone: 'visibility',
                    link: '/parametros/tipo-visualizacao',
                    roles: Object.values(menuRoles.itensMenu.parametros.tipoVisualizacao).flat()
                },
                {
                    label: 'Salas',
                    icone: 'chair',
                    link: '/parametros/salas',
                    roles: Object.values(menuRoles.itensMenu.parametros.salas).flat()
                }
            ]
        }
    ];

    constructor(
        @Inject(PERMISSOES_SERVICE_TOKEN) private _permissoesService: PermissoesService,
        private _cookie: CookieService,
        private _localStorage: LocalStorageService,
        private _controladoriaService: ControladoriaService,
        private _alerts: AlertMessageService,
        private _aplicacaoService: AplicacaoService
    ) {
        if (!environment.USE_BACKEND) {
            _localStorage.setItem('appToken', 'teste');
            _permissoesService.registrarPermissoes(['TESTE_VISUALIZAR']);
        }
        _permissoesService.initPermissoes();
    }

    async logout(event: boolean) {
        if (event) {
            try {
                this.clearCookie();
                this._localStorage.clear();
                window.open(this.URL_LOGOUT, '_self');
            } catch (error) {
                console.error(error);
            }
        }
    }

    private clearCookie() {
        let domain = '';
        if (this.MODE == 'dev' || this.MODE == 'prod') {
            domain = 'tvsbt.com.br';
        }
        this._cookie.delete(environment.ACCESS_TOKEN, '/', domain);
        this._cookie.delete(environment.REFRESH_TOKEN, '/', domain);
    }

    async _getRelatorioControladoria() {
        this._aplicacaoService.setLoadingPage(true);
        try {
            const response = await this._controladoriaService.getRelatorioControladoria();
            const blobUrl = window.URL.createObjectURL(response);
            const a = document.createElement('a');
            a.href = blobUrl;
            a.download = `Relatório_Ramais_Controladoria_${new Date().toISOString()}.xls`;
            a.click();
            window.URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error(error);
            this._alerts.mostrarMensagemErroHttp();
        } finally {
            this._aplicacaoService.setLoadingPage(false);
        }
    }
}
