import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ControladoriaService {
    URL_BASE = environment.URL_BASE + environment.PATH;

    constructor(private _http: HttpClient) {}

    getRelatorioControladoria() {
        return lastValueFrom(
            this._http.get(
                `${this.URL_BASE}/api/v1/contato/relatorio?sort=id&page=0&per_page=10&order=asc`,
                { responseType: 'blob' }
            )
        );
    }
}
